import React from "react";
import Slider from "react-slick";
import styled, { keyframes } from "styled-components";

// Assets
import Mininglogo from "../../assets/img/serviceslogos/MiningServices.png";
import EngineeringLogo from "../../assets/img/serviceslogos/Engineering.png";
import SafetyLogo from "../../assets/img/serviceslogos/Safety.png";
import SecurityLogo from "../../assets/img/serviceslogos/Security.png";
import TrainingLogo from "../../assets/img/serviceslogos/trainingservices.png";

const logos = [
  { src: Mininglogo, alt: "Mining Services Logo" },
  { src: EngineeringLogo, alt: "Engineering Logo" },
  { src: SafetyLogo, alt: "Safety Logo" },
  { src: SecurityLogo, alt: "Security Logo" },
  { src: TrainingLogo, alt: "Training Services Logo" },
];

const settings = {
  infinite: true,
  speed: 1000, // Increased speed for smoother transitions
  slidesToShow: 5,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true, 
  autoplaySpeed: 2000, // Slightly faster autoplay for better flow
  pauseOnHover: false, // Ensure it doesn't stop on hover
  cssEase: "linear", // Smooth linear transitions
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

const ClientSlider = () => (
  <SliderContainer>
    <Slider {...settings}>
      {logos.map((logo, index) => (
        <LogoWrapper key={index}>
          <ImgStyle src={logo.src} alt={logo.alt} />
        </LogoWrapper>
      ))}
    </Slider>
  </SliderContainer>
);

export default ClientSlider;

const SliderContainer = styled.div`
  width: 100%;
`;

const LogoWrapper = styled.div`
  width: 100%;
  height: 120px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;

  &:focus-visible {
    outline: none;
  }
`;

const zoomIn = keyframes`
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const moveUp = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10px);
  }
`;

const ImgStyle = styled.img`
  width: 70%;
  height: auto;
  padding: 10%;
  animation: ${zoomIn} 1s ease-in-out;
  transition: transform 0.3s ease-in-out;

  &:hover {
    animation: ${moveUp} 0.3s ease-in-out;
    transform: translateY(-10px);
  }
`;
