import React from 'react';
import { Box, Grid, Typography, Container, Link } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { FaTiktok } from 'react-icons/fa';

import logo from '../../assets/img/planuplogo2.png';

const Footer = () => {
  return (
    <Box sx={{ backgroundColor: '#0B093B', padding: '40px 0', color: 'white' }}>
      <Container maxWidth="lg">
        <Grid container spacing={4} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={3} sx={{ textAlign: 'center' }}>
            <img 
              src={logo} 
              alt="PLANUP GROUP Logo" 
              style={{ width: '150px', marginBottom: '20px' }} 
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
              Contact Us
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px', marginBottom: '10px' }}>
              <PhoneIcon />
              <Typography variant="body1"> +233203190775  +233543126295  +233531097809 </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
              <EmailIcon />
              <Link href="mailto:planup.enterprise@gmail.com" color="inherit" sx={{ textDecoration: 'none' }}>
                <Typography variant="body1">Planup.enterprise@gmail.com</Typography>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} md={3} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
              Services
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '5px' }}>
              <Typography variant="body1">MINING</Typography>
              <Typography variant="body1">SAFETY</Typography>
              <Typography variant="body1">ENG</Typography>
              <Typography variant="body1">TRAINING</Typography>
              <Typography variant="body1">SECURITY</Typography>
              <Typography variant="body1">GOODS & SERVICES</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={3} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
              Follow Us
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
              <Link href="https://facebook.com" target="_blank" color="inherit">
                <FacebookIcon fontSize="large" />
              </Link>
              <Link href="https://twitter.com" target="_blank" color="inherit">
                <TwitterIcon fontSize="large" />
              </Link>
              <Link href="https://linkedin.com" target="_blank" color="inherit">
                <LinkedInIcon fontSize="large" />
              </Link>
              <Link href="https://instagram.com" target="_blank" color="inherit">
                <InstagramIcon fontSize="large" />
              </Link>
              <Link href="https://www.tiktok.com/@planup.ent" target="_blank" color="inherit">
                <FaTiktok size={30} />
              </Link>
              <Link href="https://whatsapp.com/channel/0029VaCDavV8fewoIol8ii1t" target="_blank" color="inherit">
                <WhatsAppIcon fontSize="large" />
              </Link>
            </Box>
          </Grid>
        </Grid>
        <Typography variant="body2" sx={{ textAlign: 'center', marginTop: '40px', color: 'grey' }}>
          &copy; {new Date().getFullYear()} PLANUP GROUP. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
