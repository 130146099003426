import React, { useState, useEffect, useCallback } from 'react';
import '../../style/gallery.css';
import styled from 'styled-components';

// Import images
import G1 from "../../Data/gallery/ga1.jpg";
import G2 from "../../Data/gallery/ga2.jpg";
import G3 from "../../Data/gallery/ga3.jpg";
import G4 from "../../Data/gallery/ga4.jpg";
import G5 from "../../Data/gallery/ga5.jpg";
import G6 from "../../Data/gallery/ga6.jpg";
import G7 from "../../Data/gallery/ga7.jpg";
import G8 from "../../Data/gallery/ga8.jpg";
import G9 from "../../Data/gallery/ga9.jpg";
import G10 from "../../Data/gallery/ga10.jpg";
import G11 from "../../Data/gallery/ga11.jpg";
import G12 from "../../Data/gallery/ga12.jpg";
import G13 from "../../Data/gallery/ga13.jpg";
import G14 from "../../Data/gallery/ga14.jpg";
import G15 from "../../Data/gallery/ga15.jpg";
import G16 from "../../Data/gallery/ga16.jpg";
import G17 from "../../Data/gallery/ga17.jpg";
import G18 from "../../Data/gallery/ga18.jpg";
import G19 from "../../Data/gallery/ga19.jpg";
import G20 from "../../Data/gallery/ga20.jpg";
import G21 from "../../Data/gallery/ga21.jpg";
import G22 from "../../Data/gallery/ga22.jpg";
import G23 from "../../Data/gallery/ga23.jpg";
import G24 from "../../Data/gallery/ga24.jpg";
import G25 from "../../Data/gallery/ga25.jpg";
import G26 from "../../Data/gallery/ga26.jpg";
import G27 from "../../Data/gallery/ga27.jpg";
import G28 from "../../Data/gallery/ga28.jpg";
import G29 from "../../Data/gallery/ga29.jpg";
import G30 from "../../Data/gallery/ga30.jpg";
import G31 from "../../Data/gallery/ga31.jpg";
import G32 from "../../Data/gallery/ga32.jpg";
import G33 from "../../Data/gallery/ga33.jpg";
import G34 from "../../Data/gallery/ga34.jpg";
import G35 from "../../Data/gallery/ga35.jpg";
import G36 from "../../Data/gallery/ga36.jpg";
import G37 from "../../Data/gallery/ga37.jpg";

const images = [
  G1, G2, G3, G4, G5, G6, G7, G8, G9, G10, G11, G12, G13, G14, G15, 
  G16, G17, G18, G19, G20, G21, G22, G23, G24, G25, G26, G27, G28, 
  G29, G30, G31, G32, G33, G34, G35, G36, G37
];

export default function Gallery() {
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagesToShow, setImagesToShow] = useState(3); // Number of images to show at once
  const [displayedImages, setDisplayedImages] = useState([]);

  // Memoize the getRandomImages function to prevent unnecessary recreation
  const getRandomImages = useCallback(() => {
    const shuffledImages = images.sort(() => 0.5 - Math.random());
    return shuffledImages.slice(0, imagesToShow);
  }, [imagesToShow]); // Dependency: imagesToShow

  useEffect(() => {
    const updateImagesToShow = () => {
      if (window.innerWidth <= 768) { // Mobile view
        setImagesToShow(2);
      } else { // Desktop view
        setImagesToShow(3);
      }
    };

    updateImagesToShow(); // Initial check
    window.addEventListener('resize', updateImagesToShow); // Listen for window resize

    return () => window.removeEventListener('resize', updateImagesToShow); // Cleanup on unmount
  }, []);

  useEffect(() => {
    setDisplayedImages(getRandomImages());
    const interval = setInterval(() => {
      setDisplayedImages(getRandomImages());
    }, 3000); // Randomize images every 3 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, [getRandomImages]); // Dependency: getRandomImages

  const openLightbox = (image) => {
    setSelectedImage(image);
  };

  const closeLightbox = () => {
    setSelectedImage(null);
  };

  return (
    <div className="gallery-container">
      <Heading className="font40 extraBold">Our Gallery</Heading>
      <div className="carousel-container">
        {displayedImages.map((image, index) => (
          <div
            key={index}
            className="carousel-image"
            style={{ backgroundImage: `url(${image})` }}
            onClick={() => openLightbox(image)}
          />
        ))}
      </div>

      {/* Lightbox */}
      {selectedImage && (
        <div className="lightbox" onClick={closeLightbox}>
          <span className="close-lightbox">&times;</span>
          <img className="lightbox-image" src={selectedImage} alt="Selected" />
        </div>
      )}
    </div>
  );
}

const Heading = styled.h2`
  margin-bottom: 20px;
  text-align: center;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

