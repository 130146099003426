import React from "react";
import styled, { keyframes } from "styled-components";
import { Link } from "react-scroll";
// Assets
import CloseIcon from "../../assets/svg/CloseIcon";
import LogoIcon from "../../assets/svg/Logo";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideIn = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

const menuItems = [
  { label: "Home", to: "home" },
  { label: "Services", to: "services" },
  { label: "PPE", to: "projects" },
  // { label: "Blog", to: "blog" },
  // { label: "Pricing", to: "pricing" },
  { label: "Contact", to: "contact" },
];

export default function Sidebar({ sidebarOpen, toggleSidebar }) {
  return (
    <Wrapper open={sidebarOpen}>
      <SidebarContent>
        <SidebarHeader>
          <LogoIconWrapper>
            <LogoIcon />
          </LogoIconWrapper>
          <CloseButton onClick={() => toggleSidebar(!sidebarOpen)}>
            <CloseIcon />
          </CloseButton>
        </SidebarHeader>

        <Menu>
          {menuItems.map((item, index) => (
            <MenuItem key={index}>
              <MenuLink
                to={item.to}
                spy={true}
                smooth={true}
                offset={-60}
                onClick={() => toggleSidebar(!sidebarOpen)}
              >
                {item.label}
              </MenuLink>
            </MenuItem>
          ))}
        </Menu>

        {/* <ActionSection>
          <LoginLink href="/">Log in</LoginLink>
          <GetStartedButton href="/">Get Started</GetStartedButton>
        </ActionSection> */}
      </SidebarContent>
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  width: 250px;
  height: 100%;
  position: fixed;
  top: 0;
  right: ${({ open }) => (open ? "0" : "-250px")};
  background-color: #f5b905;
  transition: right 0.3s ease-in-out;
  z-index: 999;
`;

const SidebarContent = styled.div`
  animation: ${({ open }) => (open ? slideIn : slideOut)} 0.3s ease-in-out;
`;

const SidebarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`;

const LogoIconWrapper = styled.div`
  width: 50px;
  margin-top: 60px;
`;

const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
`;

const Menu = styled.ul`
  padding: 0 20px;
  margin-top: 40px;
`;

const MenuItem = styled.li`
  margin-bottom: 20px;
  animation: ${fadeIn} 0.5s ease forwards;
  animation-delay: ${({ index }) => `${index * 0.1}s`};
`;

const MenuLink = styled(Link)`
  display: block;
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  transition: color 0.3s ease;
  cursor: pointer;
  &:hover {
    color: #f50057;
  }
`;


