import React from 'react';
import './about.css';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from "../components/Sections/Footer";

import image1 from "../assets/img/gold.png";
import image2 from "../assets/img/gold.png";
import image3 from "../assets/img/gold.png";
import staffImage1 from "../assets/img/gold.png"; // Add your staff images here
import staffImage2 from "../assets/img/gold.png"; 
import staffImage3 from "../assets/img/gold.png"; 

import clientImage1 from "../assets/img/gold.png";
import clientImage2 from "../assets/img/gold.png";
import clientImage3 from "../assets/img/gold.png";

const AboutUs = () => {
  return (
    <div className="about-us-page">
      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-content">
          <h1>Planup Group Mining Company</h1>
          <p>Your Premier Gold Exploration and Mining Partner</p>
          <a href="#mission" className="cta-button">Discover More</a>
        </div>
        
      </section>

{/* Mission, Vision, Values Section */}
<section className="mission-vision-section" id="mission">
  <div className="container">
    <div className="row">
      <div className="col">
        <div className="icon"><i className="fas fa-bullseye"></i></div>
        <h2>Our Mission</h2>
        <ul>
          <li>To provide the very best in health, safety, security, and environmental engineering technology.</li>
          <li>Leading the way in safety, reliability, and convenience.</li>
        </ul>
      </div>

      <div className="col">
        <div className="icon"><i className="fas fa-eye"></i></div>
        <h2>Our Vision</h2>
        <ul>
          <li>To offer accessible, efficient, and reliable HSSE, PPE, fire, electrical, and construction goods and services.</li>
          <li>To provide the most technologically advanced systems to maintain safety and security in the workplace.</li>
          <li>To consistently exceed industry standards and expectations.</li>
          <li>Deliver maximum value and efficiency to our customers.</li>
          <li>Ensure our products are available where and when our customers need them.</li>
        </ul>
      </div>

      <div className="col">
        <div className="icon"><i className="fas fa-handshake"></i></div>
        <h2>Our Core Values</h2>
        <ul>
          <li>Integrity</li>
          <li>Professionalism</li>
          <li>Value for Money</li>
        </ul>
      </div>
    </div>
  </div>
</section>

      {/* Company Sectors Section */}
      <section className="company-sectors-section">
        <div className="container">
          <h2>Our Key Sectors</h2>
          <div className="row">
            <div className="sector-card">
              <div className="icon"><i className="fas fa-hammer"></i></div>
              <h3>Mining</h3>
              <p>Comprehensive mining services including gold trading, mineral exploration, and more.</p>
            </div>
            <div className="sector-card">
              <div className="icon"><i className="fas fa-shield-alt"></i></div>
              <h3>Safety</h3>
              <p>We provide safety management plans, PPE supply, and risk assessment services.</p>
            </div>
            <div className="sector-card">
              <div className="icon"><i className="fas fa-cogs"></i></div>
              <h3>Engineering</h3>
              <p>Complete engineering solutions, including construction, electricals, and welding.</p>
            </div>
            <div className="sector-card">
              <div className="icon"><i className="fas fa-graduation-cap"></i></div>
              <h3>Training</h3>
              <p>Specialized training for operators, safety officers, and engineers with certifications.</p>
            </div>
            <div className="sector-card">
              <div className="icon"><i className="fas fa-lock"></i></div>
              <h3>Security</h3>
              <p>Providing private security solutions for businesses and industries.</p>
            </div>
          </div>
        </div>
      </section>

      <Footer/>
    </div>
  );
};

export default AboutUs;
