import React, { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay, EffectCoverflow } from "swiper";
import Modal from "react-modal";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/effect-coverflow";
import ServicesData from "../Data/ServicesData";

// Initialize Swiper modules
SwiperCore.use([Navigation, Pagination, Autoplay, EffectCoverflow]);

const ServiceDetail = () => {
  const { serviceId } = useParams();
  const service = ServicesData.find((service) => service.id === serviceId);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  if (!service) {
    return <p>Service not found</p>;
  }

  const openModal = (image) => {
    setSelectedImage(image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImage(null);
  };

  return (
    <Wrapper>
      <Container>
        <Header>
          <ServiceLogo src={service.logo} alt={service.title} />
          <h1>{service.title}</h1>
          <p>{service.description}</p>
        </Header>
        <Content>
          <Image src={service.bgImage} alt={service.title} />
          <Text>
            <h2>Overview</h2>
            <p>{service.overview}</p>
            <h2>Features</h2>
            <ul>
              {service.features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
            <h2>Benefits</h2>
            <p>{service.benefits}</p>
          </Text>
        </Content>
        {/* <h1 className="extraBold">GALLERY</h1> */}
        <SwiperWrapper>
          <Swiper
            slidesPerView={3}
            spaceBetween={30}
            loop={true}
            pagination={{ clickable: true }}
            navigation={true}
            autoplay={{ delay: 3000 }}
            effect="coverflow"
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            }}
          >
            {service.images.map((image, index) => (
              <SwiperSlide key={index} onClick={() => openModal(image)}>
                <SwiperImage src={image} alt={`Slide ${index}`} />
              </SwiperSlide>
            ))}
          </Swiper>
        </SwiperWrapper>
      </Container>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '90%',
            maxHeight: '90%',
          },
        }}
      >
        {selectedImage && <ModalImage src={selectedImage} alt="Selected" />}
      </Modal>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 50px 0;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 40px;

  h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }

  p {
    font-size: 1rem;
  }
`;

const ServiceLogo = styled.img`
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const Image = styled.img`
  max-width: 600px;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  @media (min-width: 768px) {
    margin-right: 40px;
    margin-bottom: 0;
  }
`;

const Text = styled.div`
  max-width: 600px;

  h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }

  p,
  ul {
    font-size: 1rem;
    margin-bottom: 20px;
  }

  ul {
    list-style-type: disc;
    padding-left: 20px;
  }
`;

const SwiperWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 20px auto;

  .swiper-container {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: #000;
  }

  .swiper-pagination-bullet-active {
    background-color: #000;
  }
`;

const SwiperImage = styled.img`
  width: 100%;
  height: 200px; /* Adjust height as needed */
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    height: 150px; /* Adjust height for smaller screens */
  }
`;

const ModalImage = styled.img`
  width: 100%;
  height: auto;
`;

export default ServiceDetail;
