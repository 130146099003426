import React from "react";
import styled from "styled-components";

export default function FullButton({ title, action, border }) {
  return (
    <Wrapper
      className="animate pointer radius8"
      onClick={action ? () => action() : null}
      border={border}
    >
      {title}
    </Wrapper>
  );
}

const Wrapper = styled.button`
  position: relative;
  border: 2px solid ${(props) => (props.border ? "#707070" : "#7620ff")};
  background: ${(props) =>
    props.border
      ? "transparent"
      : "linear-gradient(45deg, #7620ff, #00ffab, #580cd2, #ffc107)"};
  background-size: 300% 300%;
  animation: gradientAnimation 5s ease infinite;
  width: 100%;
  padding: 15px;
  outline: none;
  color: ${(props) => (props.border ? "#707070" : "#fff")};
  font-size: 16px;
  cursor: pointer;
  border-radius: 8px;
  max-width: 220px;
  overflow: hidden;
  transition: background-color 0.3s, border 0.3s, transform 0.3s, box-shadow 0.3s;

  &:hover {
    border: 2px solid transparent;
    box-shadow: 0 0 10px rgba(118, 32, 255, 0.8),
      0 0 20px rgba(118, 32, 255, 0.6), 0 0 30px rgba(118, 32, 255, 0.4);
    transform: scale(1.1);
  }

  &:active {
    transform: scale(0.98);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }

  &:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 300%;
    height: 300%;
    background: rgba(118, 32, 255, 0.2);
    z-index: -1;
    transition: transform 0.5s ease;
    transform: translate(-50%, -50%) scale(0);
    border-radius: 50%;
  }

  &:hover:before {
    transform: translate(-50%, -50%) scale(1);
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: rgba(118, 32, 255, 0.4);
    z-index: -2;
    opacity: 0;
    animation: pulse 2s infinite;
  }

  @keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  @keyframes pulse {
    0% {
      opacity: 0.4;
      transform: scale(0.95);
    }
    70% {
      opacity: 0.6;
      transform: scale(1.05);
    }
    100% {
      opacity: 0.4;
      transform: scale(0.95);
    }
  }
`;
