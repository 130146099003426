import React, { useRef, useState } from "react";
import { Form, Button, Container, Row, Col, Card, InputGroup, Alert } from "react-bootstrap";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import emailjs from "emailjs-com";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/Registration.css';
import { motion } from "framer-motion";
import regvideo from "../ForkliftTraining/regvideo.mp4";




// Firebase config initialization
const firebaseConfig = {
  apiKey: "AIzaSyA4gv6eMcPQy4s3QRQo_OeCoHt5RW2jSO0",
  authDomain: "planupgmc.firebaseapp.com",
  projectId: "planupgmc",
  storageBucket: "planupgmc.appspot.com",
  messagingSenderId: "199029478629",
  appId: "1:199029478629:web:349e51863b22c4a603300c",
  measurementId: "G-W5P2GDYGFL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const db = getFirestore(app);


const RegistrationForm = () => {
  const form = useRef();
  const [formData, setFormData] = useState({
    name: "",
    dateOfBirth: "",
    address: "",
    contactNumber: "",
    email: "",
    occupation: "",
    age: "",
    gender: "",
    civilStatus: "",
    citizenship: "",
    height: "",
    weight: "",
    religion: "",
    language: "",
    fatherName: "",
    fatherOccupation: "",
    motherName: "",
    motherOccupation: "",
    emergencyContactName: "",
    emergencyContactNumber: "",
  });

  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData, [name]: value };
  
      if (name === "dateOfBirth") {
        const calculatedAge = calculateAge(value);
        updatedFormData.age = calculatedAge.toString();
      }
  
      if (name === "age") {
        const today = new Date();
        const birthYear = today.getFullYear() - parseInt(value);
        const birthMonth = today.getMonth() + 1 < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1;
        const birthDay = today.getDate() < 10 ? `0${today.getDate()}` : today.getDate();
        updatedFormData.dateOfBirth = `${birthYear}-${birthMonth}-${birthDay}`;
      }
  
      return updatedFormData;
    });
  };
  

  // Function to calculate age from date of birth
  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const validate = () => {
    let errors = {};

    // Basic required fields validation excluding height and weight
  Object.keys(formData).forEach((key) => {
    if (!["height", "weight"].includes(key) && !formData[key].trim()) {
      errors[key] = `${key.replace(/([A-Z])/g, ' $1')} is required`;
    }
  });

    // Basic required fields validation
    Object.keys(formData).forEach((key) => {
      if (!formData[key].trim()) {
        errors[key] = `${key.replace(/([A-Z])/g, ' $1')} is required`;
      }
    });

    // Email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.email)) {
      errors.email = "Invalid email address";
    }

    // Age validation: age must match the calculated age based on date of birth
    const calculatedAge = calculateAge(formData.dateOfBirth);
    if (formData.age && calculatedAge !== parseInt(formData.age)) {
      errors.age = `Age must match the calculated age (${calculatedAge}) based on the date of birth`;
    }

    // Gender validation: must be either "M" or "F"
    if (!["M", "F"].includes(formData.gender)) {
      errors.gender = "Gender must be either 'M' or 'F'";
    }

     setErrors(errors);
  return Object.keys(errors).length === 0;
};

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);

    if (!validate()) {
      return;
    }

    try {
      await addDoc(collection(db, "registrations"), formData);
      alert("Data saved successfully!");

      emailjs
        .sendForm(
          "service_znfohgr",
          "template_0cyfs6w",
          form.current,
          "8SFPe4G08HnGJjWgm"
        )
        .then(
          (result) => {
            alert("Email sent successfully!");
          },
          (error) => {
            console.error("Email sending failed: ", error);
          }
        );
    } catch (error) {
      console.error("Error saving data: ", error);
    }
  };

  return (
    <Container className="my-4">
      <Row className="justify-content-center">
        <Col md={6}>
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="video-container"
          >
            <Card className="custom-card video-card shadow-lg">
              <Card.Body>
                <motion.video
                  src={regvideo}
                  autoPlay
                  loop
                  muted
                  playsInline
                  className="forklift-video"
                  initial={{ scale: 0.9 }}
                  animate={{ scale: 1 }}
                  transition={{ duration: 0.3, ease: "easeInOut" }}
                ></motion.video>
              </Card.Body>
            </Card>
          </motion.div>
        </Col>

        <Col md={1} className="d-flex align-items-center">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="divider-line"
          ></motion.div>
        </Col>

        <Col md={5}>
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Card className="custom-card shadow-lg">
              <Card.Body>
                <motion.h2
                  className="text-center mb-4 animated-title"
                  initial={{ scale: 0.9 }}
                  animate={{ scale: 1 }}
                  transition={{ duration: 0.3, ease: "easeInOut" }}
                >
                  Forklift Driver Registration
                </motion.h2>
                <Form ref={form} onSubmit={handleSubmit}>
                  <Row>
                    <Col md={6}>
                      {[
                        { label: "Name", type: "text", name: "name", placeholder: "Enter your name" },
                        { label: "Address", type: "text", name: "address", placeholder: "Enter your address" },
                        { label: "Email", type: "email", name: "email", placeholder: "Enter your email" },
                      ].map((field, index) => (
                        <Form.Group controlId={`form${field.name}`} key={index}>
                          <Form.Label>{field.label}</Form.Label>
                          <InputGroup>
                            <Form.Control
                              type={field.type}
                              placeholder={field.placeholder}
                              name={field.name}
                              value={formData[field.name]}
                              onChange={handleChange}
                              required
                              className="custom-input"
                            />
                          </InputGroup>
                          {submitted && errors[field.name] && (
                            <Alert variant="danger" className="mt-2">
                              {errors[field.name]}
                            </Alert>
                          )}
                        </Form.Group>
                      ))}
                    </Col>
                    <Col md={6}>
                      {[
                        { label: "Date of Birth", type: "date", name: "dateOfBirth", placeholder: "" },
                        { label: "Contact Number", type: "text", name: "contactNumber", placeholder: "Enter your contact number" },
                        { label: "Occupation", type: "text", name: "occupation", placeholder: "Enter your occupation" },
                      ].map((field, index) => (
                        <Form.Group controlId={`form${field.name}`} key={index}>
                          <Form.Label>{field.label}</Form.Label>
                          <InputGroup>
                            <Form.Control
                              type={field.type}
                              placeholder={field.placeholder}
                              name={field.name}
                              value={formData[field.name]}
                              onChange={handleChange}
                              required
                              className="custom-input"
                            />
                          </InputGroup>
                          {submitted && errors[field.name] && (
                            <Alert variant="danger" className="mt-2">
                              {errors[field.name]}
                            </Alert>
                          )}
                        </Form.Group>
                      ))}
                    </Col>
                  </Row>
{/* New Group of Fields */}
<Row>
                    <Col md={3}>
                      <Form.Group controlId="formAge">
                        <Form.Label>Age</Form.Label>
                        <InputGroup>
                          <Form.Control
                            type="number"
                            placeholder="Enter your age"
                            name="age"
                            value={formData.age}
                            onChange={handleChange}
                            required
                            className="custom-input"
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group controlId="formGender">
                      <Form.Label>Gender</Form.Label>
                        <InputGroup>
                          <Form.Control
                            as="select"
                            name="gender"
                            value={formData.gender}
                            onChange={handleChange}
                            required
                            className="custom-input"
                          >
                            <option value="">Select Gender</option>
                            <option value="M">M</option>
                            <option value="F">F</option>
                          </Form.Control>
                        </InputGroup>
                        {submitted && errors.gender && (
                          <Alert variant="danger" className="mt-2">
                            {errors.gender}
                          </Alert>
                        )}
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group controlId="formCivilStatus">
                        <Form.Label>Civil Status</Form.Label>
                        <InputGroup>
                          <Form.Control
                            type="text"
                            placeholder="Enter your civil status"
                            name="civilStatus"
                            value={formData.civilStatus}
                            onChange={handleChange}
                            required
                            className="custom-input"
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group controlId="formCitizenship">
                        <Form.Label>Citizenship</Form.Label>
                        <InputGroup>
                          <Form.Control
                            type="text"
                            placeholder="Enter your citizenship"
                            name="citizenship"
                            value={formData.citizenship}
                            onChange={handleChange}
                            required
                            className="custom-input"
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={3}>
                      <Form.Group controlId="formHeight">
                        <Form.Label>Height</Form.Label>
                        <InputGroup>
                          <Form.Control
                            type="text"
                            placeholder="Enter your height"
                            name="height"
                            value={formData.height}
                            onChange={handleChange}
                            required
                            className="custom-input"
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group controlId="formWeight">
                        <Form.Label>Weight</Form.Label>
                        <InputGroup>
                          <Form.Control
                            type="text"
                            placeholder="Enter your weight"
                            name="weight"
                            value={formData.weight}
                            onChange={handleChange}
                            required
                            className="custom-input"
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group controlId="formReligion">
                        <Form.Label>Religion</Form.Label>
                        <InputGroup>
                          <Form.Control
                            type="text"
                            placeholder="Enter your religion"
                            name="religion"
                            value={formData.religion}
                            onChange={handleChange}
                            required
                            className="custom-input"
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group controlId="formLanguage">
                        <Form.Label>Language</Form.Label>
                        <InputGroup>
                          <Form.Control
                            type="text"
                            placeholder="Enter your language"
                            name="language"
                            value={formData.language}
                            onChange={handleChange}
                            required
                            className="custom-input"
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      {[
                        { label: "Father's Name", type: "text", name: "fatherName", placeholder: "Enter Father's Name" },
                        { label: "Mother's Name", type: "text", name: "motherName", placeholder: "Enter Mother's Name" },
                        { label: "Emergency Contact Name", type: "text", name: "emergencyContactName", placeholder: "Enter emergency contact name" },
                      ].map((field, index) => (
                        <Form.Group controlId={`form${field.name}`} key={index}>
                          <Form.Label>{field.label}</Form.Label>
                          <InputGroup>
                            <Form.Control
                              type={field.type}
                              placeholder={field.placeholder}
                              name={field.name}
                              value={formData[field.name]}
                              onChange={handleChange}
                              required
                              className="custom-input"
                            />
                          </InputGroup>
                          {submitted && errors[field.name] && (
                            <Alert variant="danger" className="mt-2">
                              {errors[field.name]}
                            </Alert>
                          )}
                        </Form.Group>
                      ))}
                    </Col>
                    <Col md={6}>
                      {[
                        { label: "Father's Occupation", type: "text", name: "fatherOccupation", placeholder: "Enter Father's Occupation" },
                        { label: "Mother's Occupation", type: "text", name: "motherOccupation", placeholder: "Enter Mother's Occupation" },
                        { label: "Emergency Contact Number", type: "text", name: "emergencyContactNumber", placeholder: "Enter emergency contact number" },
                      ].map((field, index) => (
                        <Form.Group controlId={`form${field.name}`} key={index}>
                          <Form.Label>{field.label}</Form.Label>
                          <InputGroup>
                            <Form.Control
                              type={field.type}
                              placeholder={field.placeholder}
                              name={field.name}
                              value={formData[field.name]}
                              onChange={handleChange}
                              required
                              className="custom-input"
                            />
                          </InputGroup>
                          {submitted && errors[field.name] && (
                            <Alert variant="danger" className="mt-2">
                              {errors[field.name]}
                            </Alert>
                          )}
                        </Form.Group>
                      ))}
                    </Col>
                  </Row>
                  
                  
                  <Button type="submit" className="submit-button mt-4 w-100">
                    Submit
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </motion.div>
        </Col>
      </Row>
    </Container>
  );
};

export default RegistrationForm;
