import * as React from "react";
import LogoImage from "../../assets/img/planuplogo2.png";
import styled, { keyframes } from "styled-components";

// Define the animation
const moveUp = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px); /* Increase distance */
  }
`;

// Styled component for the SVG
const SvgWrapper = styled.svg`
  animation: ${moveUp} 1s ease-in-out infinite alternate; /* Adjust duration and timing function as needed */
  filter: drop-shadow(0 0 20px rgba(255, 255, 255, 0.7)); /* Initial drop shadow */
  transition: filter 0.3s ease; /* Smooth transition for filter property */
  &:hover {
    filter: drop-shadow(0 0 30px rgba(255, 255, 255, 1)); /* Increased glow effect on hover */
    cursor: pointer; /* Change cursor to pointer on hover */
  }
`;

const LinkButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  color: inherit;
  cursor: pointer;
  text-decoration: underline;
  &:focus {
    outline: 2px solid #000; /* Ensure focus is visible */
  }
`;

function SvgComponent(props) {
  const handleClick = () => {
    window.location.reload();
  };

  return (
    <LinkButton onClick={handleClick} aria-label="Reload the page">
      <SvgWrapper xmlns="http://www.w3.org/2000/svg" width={150} height={90} viewBox="0 0 100 60" {...props}>
        <g data-name="Group 101">
          <image xlinkHref={LogoImage} width={90} height={85} />
        </g>
      </SvgWrapper>
    </LinkButton>
  );
}

export default SvgComponent;
