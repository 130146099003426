import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import styled from "styled-components";
import { Alert, Snackbar } from "@mui/material";
import AttachFileIcon from '@mui/icons-material/AttachFile'; 

// Assets
import ContactImg1 from "../../assets/img/contact-1.png";
import ContactImg2 from "../../assets/img/contact-2.png";
import ContactImg3 from "../../assets/img/contact-3.png";

export default function Contact() {
  const form = useRef();
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [buttonColor, setButtonColor] = useState("#7620ff");
  

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    file: null // Adding file to formData state
  });

  const [formErrors, setFormErrors] = useState({
    name: false,
    email: false,
    subject: false,
    message: false
  });

  const sendEmail = (e) => {
    e.preventDefault();

    const errors = {
      name: !formData.name,
      email: !formData.email,
      subject: !formData.subject,
      message: !formData.message
    };

    setFormErrors(errors);

    const hasErrors = Object.values(errors).some(error => error);

    if (hasErrors) {
      setAlertMessage("Please fill in all fields.");
      setAlertSeverity("error");
      setOpen(true);
      setButtonColor("red");
      return;
    }

    const formDataToSend = new FormData(form.current);
    if (formData.file) {
      formDataToSend.append('file', formData.file);  // Attach the file to formData
    }

    emailjs
      .sendForm(
        "service_racdi6m",
        "template_ypbwvqy",
        formDataToSend,
        {
          publicKey: '8SFPe4G08HnGJjWgm',
        }
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
          setAlertMessage("Message sent successfully!");
          setAlertSeverity("success");
          setOpen(true);
          setButtonColor("green");
          form.current.reset();
          setFormData({
            name: "",
            email: "",
            subject: "",
            message: "",
            file: null
          });
        },
        (error) => {
          console.log(error.text);
          setAlertMessage("Failed to send the message. Please try again.");
          setAlertSeverity("error");
          setOpen(true);
          setButtonColor("red");
        }
      );
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, file: e.target.files[0] });
  };

  return (
    <Wrapper id="contact">
      <LightBg>
        <Container>
          <HeaderInfo>
            <h1 className="font40 extraBold">Let's get in Touch</h1>
            <p className="font15">
              We are here to assist you with any questions or concerns you may have. 
              Whether you're looking for more information about our services, 
              need help with an ongoing project, or just want to provide feedback, 
              we'd love to hear from you. 
              <br />
              Please fill out the form below, and our team will get back to you as soon as possible. 
              Your inquiries are important to us, and we are committed to providing you with the best support possible.
            </p>
          </HeaderInfo>
          <Row style={{ paddingBottom: "30px" }}>
            <Col className="col-md-6">
              <Form ref={form} onSubmit={sendEmail}>
                <Label className="font13">First name: <Asterisk>*</Asterisk></Label>
                <Input
                  type="text"
                  id="name"
                  name="name"
                  className="font20 extraBold"
                  value={formData.name}
                  onChange={handleInputChange}
                  error={formErrors.name}
                />
                {formErrors.name && <ErrorText>Please enter your first name.</ErrorText>}
                <Label className="font13">Email: <Asterisk>*</Asterisk></Label>
                <Input
                  type="text"
                  id="email"
                  name="email"
                  className="font20 extraBold"
                  value={formData.email}
                  onChange={handleInputChange}
                  error={formErrors.email}
                />
                {formErrors.email && <ErrorText>Please enter your email.</ErrorText>}
                <Label className="font13">Subject: <Asterisk>*</Asterisk></Label>
                <Input
                  type="text"
                  id="subject"
                  name="subject"
                  className="font20 extraBold"
                  value={formData.subject}
                  onChange={handleInputChange}
                  error={formErrors.subject}
                />
                {formErrors.subject && <ErrorText>Please enter the subject.</ErrorText>}
                <Label className="font13">Message: <Asterisk>*</Asterisk></Label>
                <TextArea
                  rows="4"
                  cols="50"
                  id="message"
                  name="message"
                  className="font20 extraBold"
                  value={formData.message}
                  onChange={handleInputChange}
                  error={formErrors.message}
                />
                {formErrors.message && <ErrorText>Please enter your message.</ErrorText>}
                
                <Label className="font13">Attachment:</Label>
                <AttachmentWrapper>
                  <AttachFileLabel htmlFor="file">
                    <AttachFileIcon />
                    <span style={{ marginLeft: '8px' }}>Attach file</span>
                  </AttachFileLabel>
                  <input
                    type="file"
                    id="file"
                    name="file"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </AttachmentWrapper>
                
                <SubmitWrapper>
                  <ButtonInput type="submit" value="Send" style={{ backgroundColor: buttonColor }} />
                </SubmitWrapper>
              </Form>
            </Col>
            <Col className="col-md-6 flex">
              <ImgColumn>
                <ContactImgBox>
                  <img src={ContactImg1} alt="office" className="radius6" />
                </ContactImgBox>
                <ContactImgBox>
                  <img src={ContactImg2} alt="office" className="radius6" />
                </ContactImgBox>
              </ImgColumn>
              <ImgColumn style={{ marginTop: "100px" }}>
                <ContactImgBox>
                  <img src={ContactImg3} alt="office" className="radius6" />
                </ContactImgBox>
              </ImgColumn>
            </Col>
          </Row>
        </Container>
      </LightBg>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alertSeverity} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const LightBg = styled.div`
  background-color: #f9f9f9;
`;

const Container = styled.div`
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 0;
`;

const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  text-align: center;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;

const Col = styled.div`
  width: 100%;
  max-width: 48%;
  @media (max-width: 860px) {
    max-width: 100%;
  }
`;

const Form = styled.form`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;

const Label = styled.label`
  display: block;
  margin-bottom: 10px;
`;

const Asterisk = styled.span`
  color: red;
`;

const Input = styled.input`
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  border-bottom: 1px solid ${props => (props.error ? "red" : "#707070")};
  height: 30px;
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: bold;
`;

const TextArea = styled.textarea`
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  border-bottom: 1px solid ${props => (props.error ? "red" : "#707070")};
  min-height: 100px;
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: bold;
`;

const ButtonInput = styled.input`
  border: 1px solid #7620ff;
  background-color: #7620ff;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  border-radius: 8px;
  max-width: 220px;
  transition: background-color 0.3s, border 0.3s;

  &:hover {
    background-color: #580cd2;
    border-color: #580cd2;
  }
`;

const SubmitWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  @media (max-width: 991px) {
    width: 100%;
    justify-content: center;
    margin-bottom: 50px;
  }
`;

const ContactImgBox = styled.div`
  max-width: 180px;
  align-self: flex-end;
  margin: 10px 30px 10px 0;

  img {
    width: 100%;
    border-radius: 6px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const ImgColumn = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ErrorText = styled.p`
  color: red;
  font-size: 14px;
  margin: -20px 0 20px 0;
`;

const AttachmentWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

const AttachFileLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #7620ff;
  font-weight: bold;
`;

