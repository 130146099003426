import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link as ScrollLink } from "react-scroll";
// Components
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
// Assets
import LogoIcon from "../../assets/svg/Logo";
import BurgerIcon from "../../assets/svg/BurgerIcon";

import profile from "../../Aboutus/PGP.pdf";

export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);

  useEffect(() => {
    const handleScroll = () => setY(window.scrollY);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [y]);

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper
        className="flexCenter animate"
        style={
          y > 100
            ? {
                height: "60px",
                background: "#fff",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }
            : { height: "80px", background: "transparent" }
        }
      >
        <NavInner className="container flexSpaceCenter">
          <ScrollLink to="home" className="pointer flexNullCenter">
            <LogoIconStyled />
          </ScrollLink>
          <BurderWrapper
            className="pointer"
            onClick={() => toggleSidebar(!sidebarOpen)}
          >
            <BurgerIcon />
          </BurderWrapper>
          <UlWrapper className="flexNullCenter">
            <NavItem className="semiBold font15 pointer">
              <StyledLink
                activeClass="active"
                to="home"
                spy={true}
                smooth={true}
                offset={-80}
              >
                Home
              </StyledLink>
            </NavItem>
            <NavItem className="semiBold font15 pointer">
              <StyledLink
                activeClass="active"
                to="services"
                spy={true}
                smooth={true}
                offset={-80}
              >
                Services
              </StyledLink>
            </NavItem>
            <NavItem className="semiBold font15 pointer">
              <StyledLink
                activeClass="active"
                to="projects"
                spy={true}
                smooth={true}
                offset={-80}
              >
                Projects
              </StyledLink>
            </NavItem>
            <NavItem className="semiBold font15 pointer dropdown">
              About Us
              <DropdownContent className="dropdown-content">
                <a href={profile} target="_blank" rel="noopener noreferrer">
                  Profile
                </a>
              </DropdownContent>
            </NavItem>
            <NavItem className="semiBold font15 pointer">
              <StyledLink
                activeClass="active"
                to="contact"
                spy={true}
                smooth={true}
                offset={-80}
              >
                Contact
              </StyledLink>
            </NavItem>
          </UlWrapper>
          <UlWrapperRight className="flexNullCenter">
            <ContactButton to="contact" spy={true} smooth={true} offset={-80}>
              Contact Us
            </ContactButton>
          </UlWrapperRight>
        </NavInner>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  transition: all 0.3s ease;
  background: transparent;
`;

const NavInner = styled.div`
  position: relative;
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LogoIconStyled = styled(LogoIcon)`
  fill: #000;
  width: 60px;
  height: 60px;
  cursor: pointer;
`;

const BurderWrapper = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  height: 100%;
  display: none;
  cursor: pointer;
  @media (max-width: 760px) {
    display: block;
  }
`;

const UlWrapper = styled.ul`
  display: flex;
  list-style: none;
  gap: 25px;
  @media (max-width: 760px) {
    display: none;
  }

  .dropdown {
    position: relative;
    display: inline-block;

    &:hover .dropdown-content {
      display: block;
    }
  }
`;

const UlWrapperRight = styled.ul`
  display: flex;
  list-style: none;
  gap: 25px;
  @media (max-width: 760px) {
    display: none;
  }
`;

const NavItem = styled.li`
  margin: 0 10px;
`;

const StyledLink = styled(ScrollLink)`
  color: #333;
  padding: 8px 15px;
  text-decoration: none;
  font-weight: 600;
  position: relative;
  transition: color 0.3s ease;

  &.active {
    color: #007bff;
  }

  &:hover {
    color: #007bff;
  }

  &::after {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -5px;
    left: 0;
    background-color: #007bff;
    transition: width 0.3s ease-in-out;
  }

  &:hover::after {
    width: 100%;
  }
`;

const ContactButton = styled(ScrollLink)`
  padding: 8px 20px;
  background-color: #007bff;
  color: #fff;
  border: 2px solid #007bff;
  border-radius: 5px;
  text-decoration: none;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #0056b3;
    border-color: #0056b3;
    color: #fff;
  }
`;

const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;

  a {
    color: #333;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #f1f1f1;
      color: #007bff;
    }
  }
`;
