import React from 'react';
import styled from 'styled-components';
import CountUp from 'react-countup';
import Slider from 'react-slick';

const PlanupLandingPage = () => {
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    return (
        <Container>
            {/* Hero Section with Video Background */}
            <HeroSection>
                <HeroVideo autoPlay loop muted>
                    <source src="https://example.com/hero-video.mp4" type="video/mp4" />
                </HeroVideo>
                <HeroContent>
                    <HeroTitle>Innovating the Future of Mining</HeroTitle>
                    <HeroSubtitle>Planup Group is leading the way in sustainable mining practices across the globe.</HeroSubtitle>
                    <HeroButton>Contact Us</HeroButton>
                </HeroContent>
            </HeroSection>

            {/* About Section */}
            <AboutSection>
                <SectionTitle>About Planup Group</SectionTitle>
                <AboutText>
                    Planup Group is a pioneering mining company committed to responsible and sustainable mining practices.
                    With operations in over 50 countries, we are dedicated to delivering high-quality resources while
                    protecting the environment and contributing to local communities.
                </AboutText>
            </AboutSection>

            {/* Services Section */}
            <ServicesSection>
                <SectionTitle>Our Expertise</SectionTitle>
                <ServicesGrid>
                    <ServiceCard>
                        <ServiceIcon>🌍</ServiceIcon>
                        <ServiceTitle>Global Operations</ServiceTitle>
                        <ServiceDescription>Operating in over 50 countries worldwide, ensuring consistent supply and innovation.</ServiceDescription>
                    </ServiceCard>
                    <ServiceCard>
                        <ServiceIcon>🔋</ServiceIcon>
                        <ServiceTitle>Sustainable Practices</ServiceTitle>
                        <ServiceDescription>Committed to environmental protection and sustainable mining practices.</ServiceDescription>
                    </ServiceCard>
                    <ServiceCard>
                        <ServiceIcon>💼</ServiceIcon>
                        <ServiceTitle>Industry Leadership</ServiceTitle>
                        <ServiceDescription>Leading the mining industry with innovative technologies and practices.</ServiceDescription>
                    </ServiceCard>
                </ServicesGrid>
            </ServicesSection>

            {/* Statistics Section */}
            <StatisticsSection>
                <SectionTitle>Our Impact</SectionTitle>
                <StatsGrid>
                    <StatCard>
                        <StatNumber><CountUp end={500} duration={3} />+</StatNumber>
                        <StatLabel>Projects Completed</StatLabel>
                    </StatCard>
                    <StatCard>
                        <StatNumber><CountUp end={2000} duration={3} />+</StatNumber>
                        <StatLabel>Employees</StatLabel>
                    </StatCard>
                    <StatCard>
                        <StatNumber><CountUp end={50} duration={3} />+</StatNumber>
                        <StatLabel>Countries Operated</StatLabel>
                    </StatCard>
                </StatsGrid>
            </StatisticsSection>

            {/* Client Logos Section */}
            <ClientsSection>
                <SectionTitle>Our Trusted Partners</SectionTitle>
                <ClientsGrid>
                    <ClientLogo src="https://example.com/client1-logo.png" alt="Client 1" />
                    <ClientLogo src="https://example.com/client2-logo.png" alt="Client 2" />
                    <ClientLogo src="https://example.com/client3-logo.png" alt="Client 3" />
                    <ClientLogo src="https://example.com/client4-logo.png" alt="Client 4" />
                </ClientsGrid>
            </ClientsSection>

            {/* Timeline Section */}
            <TimelineSection>
                <SectionTitle>Our Journey</SectionTitle>
                <Timeline>
                    <TimelineEvent>
                        <EventYear>2005</EventYear>
                        <EventDescription>Company founded with a mission to innovate in the mining industry.</EventDescription>
                    </TimelineEvent>
                    <TimelineEvent>
                        <EventYear>2010</EventYear>
                        <EventDescription>Expanded operations to 10 countries.</EventDescription>
                    </TimelineEvent>
                    <TimelineEvent>
                        <EventYear>2015</EventYear>
                        <EventDescription>Awarded for excellence in sustainable practices.</EventDescription>
                    </TimelineEvent>
                </Timeline>
            </TimelineSection>

            {/* Testimonials Section */}
            <TestimonialsSection>
                <SectionTitle>What Our Clients Say</SectionTitle>
                <TestimonialSlider {...sliderSettings}>
                    <Testimonial>
                        <Quote>Planup Group is a game changer in the mining industry!</Quote>
                        <Author>John Smith, Industry Analyst</Author>
                    </Testimonial>
                    <Testimonial>
                        <Quote>Their commitment to sustainability is truly inspiring.</Quote>
                        <Author>Alice Johnson, Environmental Scientist</Author>
                    </Testimonial>
                </TestimonialSlider>
            </TestimonialsSection>

            {/* FAQ Section */}
            <FaqSection>
                <SectionTitle>Frequently Asked Questions</SectionTitle>
                <FaqItem>
                    <FaqQuestion>What services does Planup Group offer?</FaqQuestion>
                    <FaqAnswer>We specialize in sustainable mining practices, global operations, and industry leadership.</FaqAnswer>
                </FaqItem>
                <FaqItem>
                    <FaqQuestion>How can I contact Planup Group?</FaqQuestion>
                    <FaqAnswer>You can contact us through our contact form, email, or by calling our customer service line.</FaqAnswer>
                </FaqItem>
                {/* Add more FAQ items here */}
            </FaqSection>

            {/* Interactive Map Section */}
            <MapSection>
                <SectionTitle>Our Global Operations</SectionTitle>
                <WorldMap>
                    <MapTooltip>Location Info Here</MapTooltip>
                    {/* Implement hover or click interactions here */}
                </WorldMap>
            </MapSection>

            {/* Social Media Feed Section */}
            <SocialFeedSection>
                <SectionTitle>Latest from Our Social Media</SectionTitle>
                <SocialFeedGrid>
                    <SocialPost>
                        <p>Exciting updates from our latest project in Australia! #SustainableMining</p>
                    </SocialPost>
                    <SocialPost>
                        <p>Our CEO speaks on the future of mining at the Global Mining Summit. #IndustryLeadership</p>
                    </SocialPost>
                    {/* Add more social posts here */}
                </SocialFeedGrid>
            </SocialFeedSection>

            {/* Newsletter Subscription Section */}
            <NewsletterSection>
                <NewsletterText>Stay Updated with the Latest News</NewsletterText>
                <NewsletterForm>
                    <NewsletterInput type="email" placeholder="Enter your email" />
                    <NewsletterButton type="submit">Subscribe</NewsletterButton>
                </NewsletterForm>
            </NewsletterSection>

            {/* Call to Action Section */}
            <CtaSection>
                <CtaText>Ready to work with us? Get in touch today!</CtaText>
                <CtaButton>Contact Us</CtaButton>
            </CtaSection>

            {/* Footer Section */}
            <Footer>
                <FooterText>&copy; 2024 Planup Group. All rights reserved.</FooterText>
                <FooterLinks>
                    <FooterLink href="#">Privacy Policy</FooterLink>
                    <FooterLink href="#">Terms of Service</FooterLink>
                </FooterLinks>
            </Footer>
        </Container>
    );
};

// Styled Components
const Container = styled.div`
    font-family: Arial, sans-serif;
`;

const HeroSection = styled.section`
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
`;

const HeroVideo = styled.video`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
`;

const HeroContent = styled.div`
    max-width: 800px;
`;

const HeroTitle = styled.h1`
    font-size: 3rem;
    margin-bottom: 20px;
`;

const HeroSubtitle = styled.p`
    font-size: 1.5rem;
    margin-bottom: 30px;
`;

const HeroButton = styled.button`
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #4e4376;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
        background-color: #2b5876;
    }
`;

const SectionTitle = styled.h2`
    font-size: 2.5rem;
    text-align: center;
    margin: 50px 0;
    color: #2b5876;
`;

const AboutSection = styled.section`
    padding: 50px 20px;
    background-color: #f9f9f9;
`;

const AboutText = styled.p`
    font-size: 1.2rem;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    color: #555;
`;

const ServicesSection = styled.section`
    padding: 50px 20px;
`;

const ServicesGrid = styled.div`
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
`;

const ServiceCard = styled.div`
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    text-align: center;
    margin: 20px;
    width: 300px;
`;

const ServiceIcon = styled.div`
    font-size: 2.5rem;
    margin-bottom: 20px;
`;

const ServiceTitle = styled.h3`
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #2b5876;
`;

const ServiceDescription = styled.p`
    font-size: 1rem;
    color: #777;
`;

const StatisticsSection = styled.section`
    padding: 50px 20px;
    background-color: #f9f9f9;
`;

const StatsGrid = styled.div`
    display: flex;
    justify-content: space-around;
    text-align: center;
`;

const StatCard = styled.div`
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    width: 200px;
`;

const StatNumber = styled.h3`
    font-size: 2.5rem;
    color: #2b5876;
    margin-bottom: 10px;
`;

const StatLabel = styled.p`
    font-size: 1rem;
    color: #777;
`;

const ClientsSection = styled.section`
    padding: 50px 20px;
`;

const ClientsGrid = styled.div`
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    text-align: center;
`;

const ClientLogo = styled.img`
    max-width: 150px;
    margin: 20px;
`;

const TimelineSection = styled.section`
    padding: 50px 20px;
`;

const Timeline = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const TimelineEvent = styled.div`
    display: flex;
    align-items: center;
    padding: 20px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
`;

const EventYear = styled.div`
    font-size: 1.5rem;
    font-weight: 700;
    color: #2b5876;
    margin-right: 20px;
`;

const EventDescription = styled.p`
    font-size: 1.2rem;
    color: #555;
`;

const TestimonialsSection = styled.section`
    padding: 50px 20px;
`;

const TestimonialSlider = styled(Slider)`
    .slick-slide {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .slick-dots {
        bottom: -30px;
    }
`;

const Testimonial = styled.div`
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    max-width: 600px;
    text-align: center;
`;

const Quote = styled.p`
    font-size: 1.2rem;
    font-style: italic;
    color: #555;
    margin-bottom: 15px;
`;

const Author = styled.p`
    font-size: 1rem;
    font-weight: 600;
    color: #2b5876;
`;

const FaqSection = styled.section`
    padding: 50px 20px;
    background-color: #f9f9f9;
`;

const FaqItem = styled.div`
    margin-bottom: 20px;
`;

const FaqQuestion = styled.h3`
    font-size: 1.5rem;
    color: #2b5876;
`;

const FaqAnswer = styled.p`
    font-size: 1.2rem;
    color: #555;
`;

const MapSection = styled.section`
    padding: 50px 20px;
    text-align: center;
`;

const WorldMap = styled.div`
    width: 100%;
    height: 500px;
    background: url('https://example.com/world-map.jpg') no-repeat center center/contain;
    position: relative;
`;

const MapTooltip = styled.div`
    position: absolute;
    background: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
`;

const SocialFeedSection = styled.section`
    padding: 50px 20px;
    background-color: #f9f9f9;
`;

const SocialFeedGrid = styled.div`
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
`;

const SocialPost = styled.div`
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    margin: 20px;
    width: 300px;
`;

const NewsletterSection = styled.section`
    padding: 50px 20px;
    background-color: #4e4376;
    text-align: center;
    color: white;
`;

const NewsletterText = styled.p`
    font-size: 1.5rem;
    margin-bottom: 20px;
`;

const NewsletterForm = styled.form`
    display: flex;
    justify-content: center;
    gap: 10px;
`;

const NewsletterInput = styled.input`
    padding: 10px;
        font-size: 1rem;
    border-radius: 5px;
    border: none;
    width: 300px;
`;

const NewsletterButton = styled.button`
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #2b5876;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
        background-color: #1c3a5d;
    }
`;

const CtaSection = styled.section`
    padding: 50px 20px;
    text-align: center;
    background-color: #2b5876;
    color: white;
`;

const CtaText = styled.p`
    font-size: 1.5rem;
    margin-bottom: 20px;
`;

const CtaButton = styled.button`
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #4e4376;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
        background-color: #2b5876;
    }
`;

const Footer = styled.footer`
    padding: 20px 0;
    background-color: #2b5876;
    text-align: center;
    color: white;
`;

const FooterText = styled.p`
    margin: 0;
    font-size: 1rem;
`;

const FooterLinks = styled.div`
    margin-top: 10px;
`;

const FooterLink = styled.a`
    margin: 0 10px;
    font-size: 1rem;
    color: white;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
`;

export default PlanupLandingPage;

   
