import React from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
// import Projects from "../components/Sections/Projects";

import Contact from "../components/Sections/Contact";
import Faq from "../components/Sections/Faq";
import Footer from "../components/Sections/Footer"
import Projects from "../components/Sections/Projects";
import Statistics from "../components/Sections/Statistics";
import Gallery from "../components/Sections/gallery";
import Television from "../components/Sections/television";

export default function Landing() {
  return (
    <>
      <TopNavbar />
      <Header />
      <Services />
      <Statistics/>
      <Projects />
      <Television/>
      {/* <Blog />
      <Pricing /> */}
      <Gallery/>
      <Faq/>
      <Contact />
      <Footer />
    </>
  );
}


