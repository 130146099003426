import React, { useState, useEffect } from 'react';
import '../../style/television.css';

// Import videos
import V1 from "../../Data/videos/Planupvideo1.mp4";
import V2 from "../../Data/videos/Planupvideo2.mp4";
import V3 from "../../Data/videos/video3.mp4";

const videos = [V1, V2, V3];

export default function Television() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isSmallPlayerVisible, setIsSmallPlayerVisible] = useState(false);
  const [isFullScreenVisible, setIsFullScreenVisible] = useState(false);
  const [isMuted, setIsMuted] = useState(false);

  const openSmallPlayer = () => {
    setIsSmallPlayerVisible(true);
  };

  const closePlayer = () => {
    setIsSmallPlayerVisible(false);
    setIsFullScreenVisible(false);
  };

  const expandToFullScreen = () => {
    setIsFullScreenVisible(true);
  };

  const toggleMute = () => {
    setIsMuted((prevMute) => !prevMute);
  };

  // Handle video end to move to the next video
  const handleVideoEnd = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % videos.length);
  };

  return (
    <>
      {/* Floating Button */}
      <button className="floating-button" onClick={openSmallPlayer}>
        Watch Our Videos
      </button>

      {/* Small Video Player */}
      {isSmallPlayerVisible && !isFullScreenVisible && (
        <div className="small-video-player">
          <video
            key={currentIndex}
            src={videos[currentIndex]}
            className="video-player"
            autoPlay
            loop={false}  // Loop is removed to play full video once
            controls
            onEnded={handleVideoEnd}  // Trigger next video when the current one ends
            muted={isMuted}  // Bind mute state to video element
          />
          {/* Buttons appear only on hover */}
          <div className="video-controls">
            <button className="control-button" onClick={expandToFullScreen}>
              Expand
            </button>
            <button className="control-button" onClick={closePlayer}>
              &times;
            </button>
            <button className="control-button" onClick={toggleMute}>
              {isMuted ? 'Unmute' : 'Mute'}
            </button>
          </div>
        </div>
      )}

      {/* Full-Screen Television Mode */}
      {isFullScreenVisible && (
        <div className="television-overlay">
          <div className="television">
            <button className="close-television" onClick={closePlayer}>
              &times;
            </button>
            <video
              key={currentIndex}
              src={videos[currentIndex]}
              className="video-player"
              autoPlay
              loop={false}  // Loop is removed to play full video once
              controls
              onEnded={handleVideoEnd}  // Trigger next video when the current one ends
              muted={isMuted}  // Bind mute state to video element
            />
            {/* Mute/Unmute Button in Full-Screen */}
            <button className="mute-button" onClick={toggleMute}>
              {isMuted ? 'Unmute' : 'Mute'}
            </button>
          </div>
        </div>
      )}
    </>
  );
}
