import React from "react";
import styled from "styled-components";

const ServiceBox = ({ icon, title, subtitle, customStyles }) => {
  return (
    <Wrapper>
      <IconWrapper>{icon}</IconWrapper>
      <Title style={customStyles.title}>{title}</Title>
      <Subtitle style={customStyles.subtitle}>{subtitle}</Subtitle>
    </Wrapper>
  );
};

export default ServiceBox;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const IconWrapper = styled.div`
  margin-bottom: 20px;
`;

const Title = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 10px;
`;

const Subtitle = styled.p`
  font-size: 0.875rem;
  margin-bottom: 0;
  transition: max-height 0.3s ease-in-out;
`;
