import * as React from 'react';
import AccordionGroup from '@mui/joy/AccordionGroup';
import Accordion, { accordionClasses } from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionSummary from '@mui/joy/AccordionSummary';
import styled from 'styled-components';

import faq33 from '../../assets/img/Faq/faq34.png';

export default function Faq() {
  return (
    <Wrapper>
      <Content>
        <Image src={faq33} alt="FAQ 3D" />
        <FaqContainer>
          <Heading className="font40 extraBold">FREQUENTLY ASKED QUESTIONS</Heading>
          <AccordionGroup
            sx={{
              maxWidth: 700,
              margin: '0 auto',
              [`& .${accordionClasses.root}`]: {
                marginTop: '0.5rem',
                transition: '0.2s ease',
                '& button:not([aria-expanded="true"])': {
                  transition: '0.2s ease',
                  paddingBottom: '0.625rem',
                },
                '& button:hover': {
                  background: 'transparent',
                },
              },
              [`& .${accordionClasses.root}.${accordionClasses.expanded}`]: {
                bgcolor: 'background.level1',
                borderRadius: 'md',
                borderBottom: '1px solid',
                borderColor: 'background.level2',
              },
              '& [aria-expanded="true"]': {
                boxShadow: (theme) => `inset 0 -1px 0 ${theme.vars.palette.divider}`,
              },
            }}
          >
            <Accordion>
              <AccordionSummary>What is the process of Gold Exploration?</AccordionSummary>
              <AccordionDetails>
                Gold exploration involves various methods such as geological 
                mapping, geochemical sampling, geophysical surveys,
                and drilling to identify potential gold deposits.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary>How does Planup ensure responsible mining practices?</AccordionSummary>
              <AccordionDetails>
                Planup implements strict environmental protocols,
                conducts regular audits, and engages with local 
                communities to ensure responsible mining practices 
                are followed.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary>What technology does Planup utilize in its mining operations?</AccordionSummary>
              <AccordionDetails>
                Planup utilizes state-of-the-art technology including data 
                analytics, automation systems, and advanced machinery for 
                efficient and safe mining operations.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary>Why choose Planup for Gold Exploration and mining projects?</AccordionSummary>
              <AccordionDetails>
                Planup excels in operational excellence, sustainability initiatives, community engagement efforts, and maximizing stakeholder value through innovative approaches.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary>How does Planup contribute to the sustainable development of regions?</AccordionSummary>
              <AccordionDetails>
                Planup supports local economies by creating jobs, investing in infrastructure development projects, and implementing environmentally friendly practices in all operations.
              </AccordionDetails>
            </Accordion>
          </AccordionGroup>
        </FaqContainer>
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 40px 20px;
  text-align: center;
`;

const Content = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Image = styled.img`
  max-width: 300px;
  height: auto;
  margin-right: 40px;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

const FaqContainer = styled.div`
  flex: 1;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Heading = styled.h2`
  margin-bottom: 20px;
  text-align: left;

  @media (max-width: 768px) {
    text-align: center;
  }
`;
